@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
    --black: #000000;
    --white: #ffffff;
    --light-gray: #f4f5f6;
    --mid-gray: #e8ebed;
    --dark-gray: #717e88;
    --orange: #fe7d25;
    --dark-orange: #e46e20;
    --blue: #2660a5;
    --light-blue: #215e8e;
    --dark-blue: #012e51;
    --green: #0bb68c;
    --red: #ee3e38;
    --poppins-family: "Poppins", sans-serif;
}
.home-page {
    &body {
        margin: 0;
        font-family: var(--poppins-family);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--dark-blue);
        background-color: var(--white);
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
    }
    a {
        text-decoration: none;
    }
    ul {
        padding: 0;
        list-style: none;
        margin: 0;
    }
    p {
        margin-bottom: 0;
    }
    @media (min-width: 1200px) {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl {
            max-width: 1000px;
            position: relative;
        }
    }
    @media (min-width: 992px) {
        .container,
        .container-lg,
        .container-md,
        .container-sm {
            max-width: 1000px;
        }
    }
}

.home-page {
    .navbar,
    .sidebar {
        display: none;
    }
    .main {
        padding: 0;
        margin: 0;
    }

}

header {
    padding-top: 1.313rem;
    background: var(--blue);
    padding-bottom: 1.313rem;
}
.brand {
    position: relative;
    z-index: 2;
}
.header-fixed header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: transparent;
    z-index: 10;
}
.header-stickey header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: transparent;
    z-index: 10;
    background: var(--blue);
}
.brand img {
    height: 48px;
}
header nav {
    padding-top: 1rem;
}
header nav > ul {
    display: flex;
    align-items: center;
    list-style: none;
}
header nav > ul > li:not(:first-child) {
    margin-left: 0.75rem;
}
header nav > ul > li > a {
    font-size: 0.75rem;
    padding: 0.213rem 0.625rem;
    display: inline-block;
    text-align: center;
    color: var(--white);
    text-decoration: none;
    position: relative;
    z-index: 2;
}
header nav > ul > li > a span {
    position: relative;
    z-index: 2;
}
header nav > ul > li > a:hover,
header nav > ul > li > a:focus {
    color: var(--white);
}
header .nav-link {
    color: var(--white);
    position: relative;
}
header .nav-link:hover,
header .nav-link:focus {
    color: var(--white);
}
header .nav-link::before {
    width: 30px;
    height: 30px;
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background: var(--orange);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
    z-index: -1;
}
header.nav-link:hover::before,
nav > ul > li:hover a::before,
nav > ul > li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
}

/* submenu */
.sub-menu {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear;
    padding-top: 50px;
    padding-bottom: 30px;
    background: var(--blue);
    top:52px;
    z-index: 1;
}
.sub-menu.open {
    opacity: 1;
    visibility: visible;
}
.sub-menu > ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 750px;
    margin: auto;
}
.sub-menu > ul > li > a {
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    color: var(--white);
    width: 132px;
    height: 132px;
    padding: 10px;
    word-wrap: normal;
    border: 1px solid var(--white);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.sub-menu > ul > li > a:hover {
    background: var(--white);
    color: var(--blue);
}
/* buttons css */
.btn-get-started {
    font-size: 0.65rem;
    border: 1px solid var(--white);
    border-radius: 4px;
    min-width: 92px;
    margin-right: 0;
}
.btn-get-started:hover,
.btn-get-started:focus {
    background: var(--white);
    color: var(--blue);
}
.btn-not-registered,
.btn-header span {
    font-size: 0.6rem;
    color: var(--white);
    text-align: center;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: -1.475rem;
    left: 0;
    right: 0;
    width: 100%;
    white-space: nowrap;
    z-index: 2;
}
.btn-header {
    position: relative;
    margin-left: 1rem !important;
}
.main-content {
    background-image: url(../img/banner.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 640px;
    //height: 100vh;
    height: calc(100vh - 95px);
  
}

.main-banner {
    background-color: rgba(17, 59, 109, 0.6);
    padding-top: 55px;
    // height: calc(100vh - 95px);
    padding-bottom: 30px;
}

.login-doropdown {
    h1 {
        font-size: 30px;
        font-weight: 700;
        color: var(--blue);
    }

    .form-group {
        margin-bottom: 1.5rem;
    }
    .dropdown {
        position: static;
        z-index: 1000;
    }

    .dropdown-menu {
        left: auto;
        right: 0;
        min-width: 400px;
        border-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: -1px 17px 12px 0 #045682;
        border: 0;
        top: 75px;
        padding: 30px 35px;
        padding-bottom: 35px;
        &.show {
            left: auto !important;
            right: 0 !important;
            top: 27px !important;
            transform: translate(-12px, 45px) !important;
        }
    }

    .forgot-link {
        font-size: 13px;
        font-weight: 600;
        margin: 30px 0;
        display: block;
    }
    .fill-signout {
        min-width: 120px;
        justify-content: center;
    }
}

.profile-btns {
    padding: 0;
    &:hover {
        background-color: transparent;
    }
}

.login-popup {
    .btn {
        padding: 0;
        font-weight: normal;
        background-color: transparent;
        position: relative;
        border: 0;
        &:hover {
            background-color: transparent;
            border: 0;
        }
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
    .dropdown-toggle::after {
        display: none;
    }

   .btn::before {
        width: 30px;
        height: 30px;
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        background: var(--orange);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s linear;
        z-index: -1;
      }
      .btn:hover::before{
        opacity: 1;
        visibility: visible;
      }
}

.public-wrapper {
    padding: 15px 15px 50px !important;
    margin-bottom: 50px;
    h1 {
        font-size: 30px;
    font-weight: 700;
    color: var(--blue);
    margin-top: 20px;
    }

    .sub-form-title {
        border-bottom: 0px solid #fbf5f5;
        font-size: 20px;
        margin-bottom: 40px;
        padding-bottom: 0px;
        color: #012e51;
        font-weight: 600;
        margin-top: 2.5rem !important;
    }
}

.checkb {
    .custom-control-input {
        top:1.5rem !important
    }
}


///////////////////////////////////////

.outline-form .form-control::-webkit-input-placeholder {
    color: #012e51;
    font-weight: 600;
    font-size: 14px;
  }
  
  .outline-form  .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #012e51;
        font-weight: 600;
        font-size: 14px;
  }
  
  .outline-form  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #012e51;
    font-weight: 600;
    font-size: 14px;
  }
  
  .outline-form  .form-control:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #012e51;
        font-weight: 600;
        font-size: 14px;
  }


  @media screen and (max-width: 441px) {
      .brand {
        text-align: center
      }
      .home-page {
          ul {
            justify-content: center;
          }
          .container {
            width: 100%;
            max-width: 100%;
          }
          .public-wrapper {
            padding: 15px 15px 30px !important;
            h1 {
                margin-top: 0;
                font-size: 25px;
              }
          }
          .outline-form.public-wrapper h5.sub-form-title {
              width: 100%;
          }
          .outline-form.public-wrapper .register-user-btn {
            width: 100%;
          }
          .outline-form.public-wrapper .register-user-btn .col-md-8 {
              text-align: left;
          }
        .login-doropdown .dropdown-menu {
            min-width: 352px !important;
            width: 100%;
            &.show {
              transform: translate(0px, 45px) !important;
            }
           
          }
        .main-content {
            min-height: 500px;
    height: auto;
        }
        ul {
            .sub-menu{
                top: 63px;
                padding-top: 23px;
            }
         
            .sub-menu > ul {
                //display: none;
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-start;
                li {
                    width: 135px;
    margin: 10px;
                    a {
                        height: 52px;
                        width: auto;
                        font-size: 11px;
                    }
                }
            }
          }
      }
  }