@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?62fs50');
    src:  url('../fonts/icomoon.eot?62fs50#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?62fs50') format('truetype'),
      url('../fonts/icomoon.woff?62fs50') format('woff'),
      url('../fonts/icomoon.svg?62fs50#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-close:before {
    content: "\e911";
  }
  .icon-lock:before {
    content: "\e912";
  }
  .icon-support-members:before {
    content: "\e90d";
  }
  .icon-job-seeker:before {
    content: "\e90e";
  }
  .icon-chat_bubble_outline:before {
    content: "\e900";
  }
  .icon-design:before {
    content: "\e901";
  }
  .icon-favorite-outline:before {
    content: "\e902";
  }
  .icon-file_present:before {
    content: "\e903";
  }
  .icon-group-add:before {
    content: "\e904";
  }
  .icon-list_alt:before {
    content: "\e905";
  }
  .icon-manage_search:before {
    content: "\e906";
  }
  .icon-notifications-none:before {
    content: "\e907";
  }
  .icon-people_outline:before {
    content: "\e908";
  }
  .icon-post:before {
    content: "\e909";
  }
  .icon-trash:before {
    content: "\e90a";
  }
  .icon-wysiwyg:before {
    content: "\e90b";
  }
  .icon-arrow-left:before {
    content: "\e915";
  }
  .icon-edit:before {
    content: "\e914";
  }
  .icon-user-solid-square:before {
    content: "\e913";
  }
  .icon-check:before {
    content: "\e90f";
  }
  .icon-block:before {
    content: "\e910";
  }
  .icon-documents:before {
    content: "\e90c";
  }
  