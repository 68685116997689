$theme-colors: (
  'primary': #2660a5,
  'warning': #fe7d25,
  'light': #e8ebed,
  'dark': #012e51,
);

$primary: map-get($theme-colors, primary);
$secondary: map-get($theme-colors, secondary);
$warning: map-get($theme-colors, warning);
$light: map-get($theme-colors, light);
$light: map-get($theme-colors, dark);

$bg: #e8ebed;
$dark-blue: #012e51;
$grey: #f6f6f6;
$label: #717e88;
$blue-text: #215e8e;
$dark-grey: #717e88;
$dark-orange: #de6c1f;
