// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html {
  font-size: 16px;
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  background: $bg;
  font-size: 14px;
  color: #000;
}

@media (min-width: 1025px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 95%;
  }
}

@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 90%;
  }
}

/*colors*/

.white {
  color: $white;
}

.grey {
  color: #898b8c;
}

.cursor-pointer {
  cursor: pointer;
}
/* custome scrollbar */
.custome-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: $grey;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $grey;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $dark-grey;
  }
}

a {
  color: $dark-blue;
}
/* buttons */
.btn {
  font-size: 13px;
  border-radius: 10px;
  padding: 11px 25px;
  font-weight: 600;
  border-width: 2px;
  display: inline-flex;
  align-items: center;
  &.sign-out-btn {
    padding: 12px 35px;
  }
  &.btn-light {
    color: $dark-blue;
  }
}
.btn-delete {
  font-size: 15px;
  border-radius: 10px;
  padding: 8px 12px;
}
.btn-secondary {
  color: $white;
  background-color: $dark-grey;
  border-color: $dark-grey;
}
.menu-btn {
  //padding: 8px 10px;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  border-radius: 10px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.job-acrions {
  .btn-secondary {
    color: $dark-blue;
    background-color: $bg;
    border-color: $bg;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}
.btn-request {
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 5px;
  &.disabled,
  &:focus {
    cursor: default;
    box-shadow: none;
    outline: none;
  }
}
.icon-d {
  background-color: $bg;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 10px;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: $black;
  font-size: 13px;
}
.action-new-btns {
  margin-left: auto;
  width: 36px;
}

.picker-error .react-datepicker__input-container input {
  border: 1px solid red;
}

.radio-buttons {
  label {
    font-size: 14px;
    color: #012e51;
    font-weight: 600;
  }
}

.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: $white;
        border-radius: 100%;
        border: 1px solid darken($secondary, 25%);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: 0;
        margin-right: 10px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $warning;
          box-shadow: inset 0 0 0 4px $white;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $warning;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $secondary;
          border-color: darken($secondary, 25%);
          background: darken($white, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.radio-new {
  margin: 8px 0;
  flex-basis: 50%;
  label {
    display: inline-flex;
    align-items: center;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: $white;
        border-radius: 100%;
        border: 2px solid #2660a5;
        display: inline-block;
        width: 24px;
        height: 24px;
        position: relative;
        top: 0;
        margin-right: 10px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: #6b8db7;
          //background-color: #d3dfea;
          // box-shadow: inset 0 0 0 4px $white;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #2660a5;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $secondary;
          border-color: darken($secondary, 25%);
          background: darken($white, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

// checkbox
.custom-checkbox {
  // input {
  //   padding: 0;
  //   height: initial;
  //   width: initial;
  //   margin-bottom: 0;
  //   display: none;
  //   cursor: pointer;
  // }
  // label {
  //   position: relative;
  //   cursor: pointer;
  //   color: #012e51;
  //   font-size: 13px;
  //   font-weight: 500;
  // }
  // label:before {
  //   content:'';
  //   -webkit-appearance: none;
  //   background-color: transparent;
  //   border: 2px solid $warning;
  //   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  //   padding: 8px;
  //   display: inline-block;
  //   position: relative;
  //   vertical-align: middle;
  //   cursor: pointer;
  //   margin-right: 15px;
  // }
  // input:checked + label:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 2px;
  //   left: 9px;
  //   width: 6px;
  //   height: 14px;
  //   border: solid $warning;
  //   border-width: 0 2px 2px 0;
  //   transform: rotate(45deg);
  // }
  .custom-control-input {
    z-index: 1;
  }
}

.hide-btns {
  .action-new-btns {
    display: none;
  }
}

.hide-show-btns {
  .btn-like {
    display: none !important;
  }
}

.hide-heart-icon {
  .btn-like {
    display: none !important;
  }
}

/* inputs */
.inputclass {
  &.form-control {
    background-color: $grey;
    border-color: $grey;
    color: $dark-blue;
    font-size: 13px;
    font-weight: 600;
    height: 45px;
    border-radius: 10px;
    padding: 6px 20px;
  }
}
.form-control-file {
  background-color: $grey;
  border-color: $grey;
  color: $dark-blue;
  font-size: 13px;
  font-weight: 600;
  height: 45px;
  border-radius: 10px;
  padding: 10px 20px 6px 20px;
}
textarea {
  &.inputclass {
    &.form-control {
      height: auto;
      padding-top: 20px;
    }
  }
}

.search-control {
  background-color: $white !important;
  border-color: $white !important;
  height: 40px !important;
  border-radius: 0.25rem !important;
  padding: 6px 8px !important;
}

// select {
//   -webkit-appearance: none;
// -moz-appearance: none;
// appearance: none;

// }

.with-icon {
  position: relative;
  .fa-icon {
    position: absolute;
    top: 17px;
    left: 15px;
    font-size: 12px;
  }
  label + .fa-icon {
    top: 44px;
  }
  .form-control {
    padding-left: 40px;
  }
}

.error {
  font-size: 13px;
  margin-top: 5px;
}
small {
  font-size: 60%;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background-color: $primary;
  transition: all 0.5s;
  bottom: 0;
  float: none;
  left: 0px;
  position: fixed;
  top: 0;
  z-index: 1051;
  overflow-y: auto;
  @extend .custome-scrollbar;
  &.is-open {
    left: -250px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 10px 30px;
      a {
        color: $white;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        width: 170px;
        margin: 0 auto;
        padding: 10px 15px;
        span {
          //margin-right: 15px !important;
          font-size: 14px;
          width: 36px;
        }
        &.is-active {
          background-color: $white;
          color: $dark-blue;
          border-radius: 71px;
        }

        .ricon {
          svg {
            font-size: 22px;
          }
          &.fi {
            svg {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .sidebar-header {
    margin-bottom: 50px;
    padding: 10px 10px 0;
    a {
      width: auto;
      padding: 0;
      display: block;
      text-align: center;
      &.JIN-brand {
        img {
          width: 80%;
        }
      }
    }
  }
  h5 {
    font-size: 15px;
    font-weight: 700;
    color: $white;
    border-bottom: 1px solid $white;
    margin-bottom: 10px;
    padding-bottom: 10px;
    margin: 15px;
  }
}

/* top nav */

.navbar {
  margin-left: 250px;
  height: 90px;
  &.is-open {
    margin-left: 0;
  }
}

.main {
  margin-left: 250px;
  margin-top: 90px;
  padding-top: 30px;
  padding-bottom: 30px;
  &.is-open {
    margin-left: 0;
  }
}

/* top right header*/
.search-btn {
  background-color: $primary;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  border-radius: 10px !important;
  border: 0;
  display: inline-flex;
  overflow: hidden;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.nav-right {
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  .icon {
    a {
      background-color: $primary;
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 10px;
      border: 0;
      display: inline-flex;
      overflow: hidden;
      color: $white;
      align-items: center;
      justify-content: center;
    }
  }
  .profile {
    background-color: $white;
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 50%;
    //border: 0;
    display: inline-block;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }
  .dropdown-toggle::after {
    border: 0;
  }
}

.profile-dropdown {
  .dropdown-menu {
    left: auto;
    right: 0;
    //max-height: 364px;
    width: 363px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: #808d94 -1px 17px 12px 0px;
    border: 0;
    top: 75px;
    padding: 40px;
  }

  .profile-btns {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
    .btn {
      padding: 15px 20px;
      font-weight: 500;
      .icon {
        margin-right: 10px;
      }
      &.btn-primary {
        background-color: $dark-blue;
      }
    }
    .fill-signout {
      border-radius: 4px;
      padding: 12px 32px;
    }
  }
}

.page-title {
  font-size: 24px;
  color: $dark-blue;
  font-weight: 600;
  margin-bottom: 30px;
}

.page-sub-title {
  font-size: 20px;
  color: $primary;
  font-weight: 600;
  margin-bottom: 25px;
}

.page-sub-title-with-back {
  font-size: 20px;
  color: $primary;
  font-weight: 600;
  margin-bottom: 25px;
  svg {
    color: $warning;
    margin-right: 10px;
  }
}

/* dashboar css */
.dashboard-wrapper {
  .dashboard-box {
    padding: 30px 35px;
  }
  .dashboard-col-bg {
    background-color: $white;
    color: $dark-blue;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}

/* job list dashboard css*/
.job-list {
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  li {
    background-color: $grey;
    padding: 15px;
    display: flex;
    margin-bottom: 20px;
    border-radius: 6px;
    padding-bottom: 20px;

    .icon {
      background-color: $white;
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 50%;
      border: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: 20px;
      color: $success;
      font-size: 13px;
    }
    .job-details {
      flex-basis: 58%;
      h5 {
        font-size: 13px;
        color: $dark-blue;
        font-weight: 600;
      }
      h4 {
        font-size: 14px;
        color: $primary;
        font-weight: 500;
        word-break: break-word;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .job-post-date {
      font-size: 12px;
      color: $black;
      padding-top: 0px;
      flex-basis: 15%;
    }

    .avatar {
      flex: 0 0 auto;
      width: 50px;
      height: 50px;
      padding: 0;
      border-radius: 50%;
      border: 1px solid #bfbfbf;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: 20px;
      img {
        max-width: 100%;
      }
    }
    .message-details {
      h4 {
        font-size: 13px;
        color: $dark-blue;
        font-weight: 600;
        margin-top: 5px;
        + h4 {
          color: $blue-text;
          margin: 15px 0;
          font-weight: 700;
        }
      }

      p {
        font-size: 13px;
      }
    }
  }
}

// resume list dashboard css

.resume-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    background-color: $grey;
    padding: 15px;
    display: flex;
    margin-bottom: 20px;
    border-radius: 6px;
    padding-bottom: 20px;
    .fav-icon {
      background-color: $white;
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 10px;
      border: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      color: $black;
      font-size: 13px;
      &.btn-danger {
        background-color: $danger;
        color: $white;
      }
      &.btn-secondary {
        background-color: $secondary;
        color: $white;
      }
      &.btn-white {
        background-color: $white;
        color: $black;
      }
      &.btn-light {
        background-color: #e8ebed;
        color: $black;
      }
    }
    .resume-details {
      h5 {
        font-size: 13px;
        color: $dark-blue;
        font-weight: 600;
        a {
          padding: 0;
          font-size: 13px;
          color: $dark-blue;
          font-weight: 600;
        }
      }
      h4 {
        font-size: 14px;
        color: $dark-blue;
        font-weight: 600;
        margin-bottom: 15px;
      }
      .date-location {
        font-size: 13px;
        color: $dark-blue;
        font-weight: 600;
        line-height: 2;
        .text {
          color: $primary;
        }
      }
      > a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .job-post-date {
      font-size: 12px;
      color: $black;
    }
  }
}

.dot {
  font-size: 40px;
  font-weight: 700;
  margin: 0 10px;
  line-height: 0;
  color: $black;
}

.r-title {
  font-size: 13px;
  color: $dark-blue;
  font-weight: 600;
}
.r-text {
  font-size: 13px;
  color: $black;
  font-weight: 400;
}

.all-details {
  font-size: 13px;
  color: $primary;
  font-weight: 500;
  line-height: 2;
  .j-title {
    font-size: 13px;
    color: $dark-blue;
    font-weight: 500;
  }
}

.resume-content {
  h2 {
    font-size: 20px;
    color: #012e51;
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    font-size: 13px;
    color: $dark-blue;
    font-weight: 400;
  }
  h3 {
    font-size: 18px;
    color: $primary;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      font-size: 13px;
      color: $dark-blue;
      font-weight: 400;
      padding: 5px 0;
      word-break: break-word;
    }
  }
  .company-text {
    font-size: 16px;
    color: $black;
    font-weight: 600;
    margin: 0px 0 5px;
  }
  .exp-text {
    font-size: 15px;
    color: $black;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 4px;
  }
  .mark-and-grade {
    margin-bottom: 30px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }
  .points-2 {
    padding-left: 16px;
  }
  .points {
    padding-left: 16px;
    li {
      padding: 5px 0;
      width: 100%;
    }
  }
}

// Resumes page css

.resumes-page {
  .resume-list {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    margin-left: -20px;
    li {
      flex-basis: 31%;
      margin-left: 20px;
    }
  }
}

.post-job-container {
  .saved-job {
    font-size: 14px;
    span {
      @extend .icon-d;
      background-color: $white;
      color: $warning;
    }
  }
}

.sponser-dashboard-col {
  .email,
  .phone,
  .company {
    display: none;
  }
  .job-list {
    .headings {
      display: none !important;
    }
    li {
      padding: 10px 15px;
      .avatar {
        width: 50px;
        height: 50px;
      }
      .name {
        width: 100%;
      }
    }
  }
}

.support-team-list {
  .list-coulmn-right {
    flex-basis: 100%;
    display: flex;
  }
  li {
    padding: 15px 15px;
    div {
      font-size: 13px;
      color: $dark-blue;
      font-weight: 600;
      // word-break: break-all;
    }
    .avatar {
      width: 50px;
      //width: 10%;
      height: 50px;
    }
    .name {
      width: 11%;
    }
    .email {
      width: 15%;
    }
    .phone {
      width: 11%;
    }
    .company {
      width: 15%;
      &.company-pd {
        padding-right: 16px;
      }
    }
    .status {
      width: 11%;
    }
    .position {
      width: 11%;
    }
    .statust {
      width: 11%;
    }
    .subcat {
      width: 15%;
    }

    .width-t1 {
      width: 20%;
    }
    .width-t2 {
      width: 20%;
    }
    .width-t3 {
      width: 20%;
    }
    .width-t4 {
      width: 30%;
    }
    .width-t5 {
      width: 10%;
    }
  }
  .headings {
    background-color: transparent;
    div {
      font-size: 15px;
      color: $dark-blue;
      font-weight: bold;
    }
    .avatar {
      width: 50px;
      height: 1px !important;
    }
  }
}

.job-seekers-list {
  div {
    word-break: break-all;
  }
  li {
    .email {
      width: 40%;
    }
    .name2 {
      width: 20%;
    }
    .email2 {
      width: 35%;
    }
    .phone2 {
      width: 20%;
    }
    .status2 {
      width: 10%;
    }
    .action2 {
      width: 15%;
    }
  }
}

.sponsership-list {
  li {
    padding: 15px 15px;
    div {
      font-size: 13px;
      color: $dark-blue;
      font-weight: 600;
      word-break: normal;
      margin-right: 5px;
    }
    .avatar {
      width: 60px;
      height: 60px;
    }
    .name {
      width: 20%;
    }
    .email {
      width: 30%;
    }
    .phone {
      width: 20%;
    }
    .company {
      width: 20%;
    }
  }
  .headings {
    background-color: transparent;
    div {
      font-size: 15px;
      color: $dark-blue;
      font-weight: bold;
    }
    .avatar {
      width: 60px;
      height: 1px !important;
    }
  }
}

.sponsership-page {
  .job-list {
    width: 100%;
  }
  .alphabates {
    width: 50px;
    text-align: right;
    padding-top: 58px;
    div,
    a {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      color: $dark-blue;
      font-weight: 400;
      line-height: 2;
      cursor: pointer;
    }
  }
  .support-team-list li .name {
    padding-right: 16px;
  }
  .support-team-list li .position {
    padding-right: 6px;
  }
}

.applied-list {
  padding: 10px 35px;
  li {
    padding: 15px 15px;
    //margin-bottom: 0 !important;
    border-radius: 0 !important;
    //border-bottom: 1px solid #f1efef;
    &.headings {
      border-bottom: 0 !important;
    }
    a:hover {
      text-decoration: underline !important;
    }
    &:last-child {
      border-bottom: 0 !important;
    }
  }
  .name {
    width: 30% !important;
  }
  .email {
    width: 30% !important;
  }
  .phone {
    width: 40% !important;
  }
}

.user-details {
  margin-bottom: 10px;
  margin-top: 40px;
  .user-avatar {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    padding: 0;
    border-radius: 50%;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 20px;
    img {
      max-height: 100%;
    }
  }
  .user-name {
    font-size: 18px;
    color: $dark-blue;
    font-weight: 600;
  }
}

.require-field {
  &::after {
    content: "*";
    color: #012e51;
    position: absolute;
    right: 6px;
    top: 0;
  }
}

.users-wrap {
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
  .users-box {
    flex-basis: 45%;
    &.left {
      label {
        font-size: 13px;
        color: $label;
        margin-bottom: 0;
      }
      .inputclass.form-control {
        background-color: transparent;
        border: 0 !important;
        border-radius: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-bottom: 1px solid $grey !important;
        height: auto !important;
      }
      .with-icon .fa-icon {
        right: 5px !important;
        left: auto;
        color: $label;
        top: 30px;
        cursor: pointer;
      }
    }
    &.right {
      .note {
        padding: 15px 10px 15px 30px;
        margin-top: 0px;
        &.note-bg {
          background-color: $grey;
          border-radius: 6px;
        }
        p {
          font-size: 13px;
          line-height: 1.7;
          font-weight: 300;
          color: $dark-blue;
        }
        .note-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          h4 {
            font-size: 14px;
            color: $dark-blue;
            font-weight: 500;
          }

          i {
            color: $label;
            cursor: pointer;
          }
        }
      }
    }
  }
  //  job details css
  &.job-details-wrap {
    padding: 45px 35px 30px;
    &.btns-width {
      .btn {
        padding: 11px 16px !important;
      }
    }
    .left {
      flex-basis: 100% !important;
    }
    .job-title {
      font-size: 16px;
      color: $dark-blue;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .company-name {
      font-size: 13px;
      color: $dark-blue;
      font-weight: 500;
    }
    .post-date {
      font-size: 13px;
      color: $primary;
      font-weight: 500;
      margin: 10px 0;
      word-break: break-all;
      span {
        color: $dark-blue;
      }
    }
    .location {
      font-size: 13px;
      color: $primary;
      font-weight: 500;
      span {
        color: $dark-blue;
      }
    }
  }
}

.outline-form {
  .inputclass.form-control {
    background-color: transparent;
    border: 2px solid $primary !important;
    border-radius: 8px;
    min-height: 50px;
    font-weight: 600;
    font-size: 14px;
  }
  label {
    font-weight: 500;
    font-size: 14px;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 12px;
    background-origin: content-box;
    background-image: url(../img/arrow.svg);
  }
  .custom-checkbox {
    .custom-control-label {
      padding-left: 20px;
      color: #717e88;
      font-size: 13px;
      font-weight: 500;
      &::before {
        width: 22px;
        height: 22px;
        border: 2px solid #adb5bd;
        top: 0;
      }
      &::after {
        left: -21px;
        background: 60% / 70% 70% no-repeat;
      }
      a {
        color: #2660a5;
        text-decoration: underline;
      }
    }
  }
}

.job-description {
  padding: 10px 35px;
  h3 {
    font-size: 14px;
    color: $primary;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 300;
    color: $dark-blue;
  }
}

.recent-communication {
  h3 {
    font-size: 15px;
    color: $dark-blue;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  li {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
  .message-box {
    background-color: $grey;
    padding: 15px;
    display: flex;
    border-radius: 6px;
    width: 49%;
    padding-bottom: 20px;
    margin: 10px 0;
    a {
      font-size: 10px;
      color: $label;
      font-weight: bold;
      display: block;
      margin-top: 13px;
    }

    &.me {
      background-color: $primary;
      color: $white !important;
      a {
        color: $white !important;
      }
      .message-details {
        width: 100%;
        h4 {
          color: $white !important;
          display: flex;
          width: 100%;
          margin-bottom: 15px;
          .communication-date {
            font-weight: normal;
          }
        }
      }
      .job-post-date {
        color: $white !important;
      }
    }
  }

  li {
    .message-box {
      float: right;
      &.other {
        float: left;
      }
    }
  }

  // li:nth-child(odd) {
  //   .message-box {
  //     float: left;
  //   }
  // }
  // li:nth-child(even) {
  //   .message-box {
  //     float: right;
  //   }
  // }
}
.resume-communication {
  padding: 10px 5px;
}

// pagination css

.pagination {
  align-items: center;
  .page-item {
    .page-link {
      border: 0;
      border-radius: 4px;
      padding: 0.4rem 0.75rem;
      line-height: 1;
      &.current {
        background-color: $dark-blue;
      }
      &.active {
        background-color: $dark-blue;
      }
      [aria-hidden="true"] {
        font-size: 20px;
      }
    }
    .active {
      .page-link {
        background-color: $dark-blue;
      }
    }
  }
  // .first {
  //   .page-link {
  //     position: relative;
  //     color: #000;
  //   }
  //   .page-link::before {
  //     content: "\f053";
  //     font-family: "Font Awesome 5 Free";
  //     font-weight: 900;
  //   }
  // }

  // .prev {
  //   .page-link {
  //     position: relative;
  //     color: #000;
  //   }
  //   .page-link::before {
  //     content: "\f053";
  //     font-family: "Font Awesome 5 Free";
  //     font-weight: 900;
  //   }
  // }
}

.pagination {
  align-items: center;
  li {
    a {
      padding: 0.4rem 0.75rem;
    }
    a[rel="prev"] {
      font-size: 20px;
    }
    a[rel="next"] {
      font-size: 20px;
    }
  }
  .page-link {
    position: relative;
    display: block;
    padding: 5px 10px;
    margin-left: -1px;
    border-radius: 4px;
    line-height: 1;
    color: #2660a5;
    background-color: #fff;
    //border: 1px solid #dee2e6;
  }
  .active {
    .page-link {
      background-color: $dark-blue !important;
      color: #fff !important;
    }
  }
}
.react-datepicker {
  font-family: "Poppins", sans-serif !important;
  display: flex !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.4rem !important;
  line-height: 1.4rem !important;
}

.react-datepicker__time-container {
  width: 72px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 72px !important;
}
.calender-icon {
  position: relative;
  input {
    padding-left: 40px !important;
  }
  .icon {
    position: absolute;
    top: 14px;
    left: 16px;
  }
}

///////////////////////////////////////

.form-control::-webkit-input-placeholder {
  color: $dark-blue;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: $dark-blue;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: $dark-blue;
}

.form-control:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: $dark-blue;
}

// .collapse.show,
// .collapsing {
//   background: #313b4c;
// }

// .nav-item {
//   /*color: #ced4da;*/
//   color: #adb5bd;
//   font-weight: 300;
// }

// .nav-item.open {
//   color: #e9ecef;
//   background: #313b4c;
// }

// .nav-item.active {
//   color: #e9ecef;
//   background: #2d3646 !important;
// }

// .nav-item:hover {
//   background: #313b4c;
//   color: #fff;
//   transition: 0.2s;
// }

// .nav-item .svg-inline--fa {
//   color: inherit;
// }

// li a.dropdown-toggle::after {
//   display: inline-flex;
//   position: relative;
//   left: 60%;
//   top: 10%;
// }

// .sidebar-header > button {
//   position: relative;
//   float: right;
//   margin: 0.5em;
//   font-size: 2rem;
//   cursor: pointer;
//   display: none;
// }

//  seekers css
.btn-link {
  font-size: 12px;
  color: $warning;
}
.btn-link {
  &:hover {
    color: $warning;
  }
}
.btn-like.btn-warning {
  font-size: 12px;
  color: $warning;
}
.btn-like.btn-warning {
  &:hover,
  &:focus,
  &.active {
    background-color: $warning;
    border-color: $warning;
    color: $white;
  }
}
.btn-link:hover {
  color: $warning;
}
.btn-warning {
  color: $white;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.saved-resume {
  .btn-like.btn-warning {
    background-color: $warning;
    border-color: $warning;
    color: $white;
  }
}

.discription {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 5px;
  color: $dark-blue;
}
.job-list {
  li {
    .fav-icon {
      flex-basis: 140px;
    }
  }
}
.resumes-page {
  .saved-jobs-list {
    justify-content: start;
    > li:not(:last-child) {
      margin-right: 30px;
    }
  }
}
.upload-box {
  position: relative;
  width: 200px;
  .form-control-file {
    position: absolute;
    top: 16px;
    opacity: 0;
    z-index: 1;
    height: 44px;
    cursor: pointer;
  }
}
.upload-cv-box {
  background-color: $grey;
  padding: 18px;
  border-radius: 10px;
  h4,
  label {
    color: $dark-blue;
    font-size: 14px;
    font-weight: 700;
    span {
      font-size: 13px;
      color: $primary;
      font-weight: normal;
      margin-left: 10px;
    }
  }
  label {
    font-size: 13px;
  }
}
.resume-notes {
  background-color: $grey;
  padding: 18px;
  border-radius: 10px;
  position: relative;
  padding-right: 70px;
  p {
    font-size: 13px;
    color: $dark-blue;
    font-weight: normal;
  }
  label {
    color: $dark-blue;
    font-size: 12px;
    font-weight: 700;
  }
  span {
    color: $primary;
  }
  .btn-delete {
    position: absolute;
    right: 20px;
  }
}
.inbox-wrap {
  display: flex;
  justify-content: flex-start;
  .inbox-menu {
    flex-basis: 12%;
    padding-top: 65px;
    ul {
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 5px;
        a {
          font-size: 13px;
          line-height: 20px;
          font-weight: 700;
          color: $dark-blue;
          padding: 7px 15px;
          display: flex;
          align-items: center;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          &.active,
          &:hover {
            background: $bg;
            text-decoration: none;
          }
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
  .inbox-messages {
    padding-top: 20px;
    padding-left: 20px;
    flex-basis: 30%;
    max-height: 550px;
    overflow: hidden;
    overflow-y: auto;
    .list-group {
      margin-top: 20px;
    }
    .list-group-item {
      border: none;
      margin-bottom: 0px;
      background-color: $grey;
      padding: 15px;
      border-radius: 10px;
      &.active {
        color: $white;
        background-color: $primary;
        h4,
        label,
        p,
        span {
          color: $white;
        }
      }
      h4 {
        color: $dark-blue;
        font-size: 13px;
        font-weight: 700;
      }
      label {
        color: $dark-blue;
        font-size: 12px;
        font-weight: 700;
      }
      p {
        color: $dark-blue;
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 0;
      }
    }
    .pagination {
      flex-wrap: wrap;
      margin-top: 15px;
    }
  }
  .inbox-message {
    flex-basis: 58%;
    padding: 20px 20px 0;
    border-left: 2px solid $bg;
    margin-left: 20px;
    .email-actions {
      margin-bottom: 20px;
      .btn {
        font-size: 15px;
        padding: 7px 12px;
        margin-left: 10px;
      }
      .btn-secondary {
        color: $dark-grey;
        background-color: $bg;
        border-color: $bg;
        &:hover {
          background-color: $dark-blue;
          color: $white;
        }
      }
    }
    .avatar {
      background-color: #fff;
      width: 50px;
      height: 50px;
      padding: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border: 1px solid #e8ebed;
      margin-right: 10px;
      img {
        max-width: 100%;
      }
    }
  }
  .msg-time {
    color: $dark-blue;
    font-size: 11px;
    font-weight: normal;
    width: auto;
    display: inline-flex;
    margin-left: auto;
  }
  .abbr {
    font-size: 17px;
    width: 40px;
    height: 40px;
    background: $primary;
    color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.main-message {
  padding: 0 0px 30px 0px;
  .main-msg-header {
    display: flex;
    align-items: center;
    h3 {
      font-size: 15px;
      font-weight: 700;
      color: $dark-blue;
      margin-bottom: 0;
      //flex-basis: 75%;
    }
  }
  .message-to,
  .message-subject,
  .message-discription p {
    font-size: 12px;
    font-weight: normal;
    color: $dark-blue;
    word-break: break-word;
  }
  .message-subject {
    font-weight: 700;
    color: $primary;
  }
  .message-subject,
  .message-to,
  .message-discription {
    padding-left: 62px;
  }
}
.message-header,
.header-with-search {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-title {
    margin-bottom: 0;
  }
  .messages-search {
    width: 300px;
    .input-group-text,
    .form-control {
      background-color: $white;
      border: none;
      font-size: 12px;
      font-weight: 700;
      color: $dark-blue;
      min-height: 40px;
    }
    .form-control {
      padding-left: 0;
    }
    .form-control:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
.inbox-messages .list-group,
.inbox-message .message-discription {
  max-height: 630px;
  overflow-y: auto;
  @extend .custome-scrollbar;
}

// message box css start
.message-container {
  position: absolute;
  left: 140px;
  right: auto;
  top: 0;
  bottom: 0;
  margin: 20px auto;
  background: #fff;
  width: 815px;
  z-index: 3;
  box-shadow: 0px 0px 6px 3px #dedede;
  border-radius: 10px;
  &.custome-msg-container {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
  .message-header-box {
    background: $warning;
    padding: 15px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-size: 13px;
      font-weight: 700;
      color: $white;
      margin-bottom: 0;
    }
    .messages-modal-actions {
      .btn {
        padding: 5px;
        background: transparent;
        border: none;
        margin-left: 5px;
        border-radius: 50%;
        &:hover,
        &:focus {
          background: $dark-orange;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
  .message-body {
    padding: 30px 30px 0;
  }
  .message-footer {
    padding: 15px 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      padding: 6px 15px !important;
      border-radius: 5px !important;
      margin-right: 10px;
    }
    .btn-outline-secondary {
      padding: 7px 7px !important;
      border: 0;
      font-size: 15px;
    }
  }
  .recipients .form-control,
  .subject .form-control {
    font-size: 12px;
    font-weight: 700;
    color: $dark-blue;
    border: 0;
    border-bottom: 1px solid $bg;
    border-radius: 0;
    padding: 0;
    min-height: 40px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .main-message-box {
    margin-top: 30px;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    color: $dark-blue;
    height: 353px;
    overflow-y: auto;
    @extend .custome-scrollbar;
  }
}
// message box css end
.network-page {
  .alphabates {
    width: 30px;
    text-align: center;
    margin-left: 22px;
    > a,
    .btn {
      margin-bottom: 4px;
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.abuse-list {
  li {
    div {
      padding-right: 10px;
    }
  }
}
.network-list {
  width: 100%;
  @extend .job-list;
  > li {
    min-height: 50px;
    > a {
      display: flex;
      width: 100%;
    }
  }
  .title {
    width: 16%;
    padding-right: 10px;
  }
  .industry-category {
    width: 10%;
    padding-right: 15px;
  }
  .company-name {
    width: 18%;
    padding-right: 15px;
  }
  .group-td {
    width: 14%;
    padding-right: 15px;
  }
  .state {
    width: 16%;
  }
  .city {
    width: 12%;
  }
  .res-td {
    width: 7%;
  }
  .action {
    width: 7%;
    min-width: 92px;
  }
}
.modal {
  z-index: 1055; // keep modal above the navigation bar
}
.modal-dialog {
  .modal-content {
    border-radius: 12px;
  }
}
@media (min-width: 576px) {
  .modal-resume {
      max-width: 800px;
  }
}

.input-add-btn {
  display: flex;
  .form-group {
    flex-basis: 100%;
  }
  .btn-outline-secondary {
    height: 43px;
    color: $dark-grey;
    padding: 10px 15px;
    margin-left: 10px;
    min-width: 130px;
    justify-content: center;
    &:hover {
      color: $white !important;
    }
    &:focus {
      color: $dark-grey;
    }
  }
}
.detail-view {
  .post-date,
  .location {
    span {
      display: block;
    }
  }
}
.jin-resumes {
  h3 {
    font-size: 14px;
    color: $primary;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $bg;
  }
}
.badge {
  margin-right: 10px;
  margin-bottom: 10px;
  color: $dark-blue;
  padding: 4px 15px;
  background-color: $grey;
  border-color: $grey;
  white-space: normal;
  text-align: left;
  line-height: 16px;
  position: relative;
  padding-right: 30px;
  word-break: break-all;
  span {
    padding: 0;
  }
  .btn-link {
    color: $dark-blue;
    padding: 0;
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    &:hover,
    &:focus {
      text-decoration: none;
      box-shadow: none;
    }
  }
}

.respond-modal {
  .modal-dialog .modal-content {
    border-radius: 5px;
  }
  .modal-header {
    padding: 0.5rem 1rem;
    .modal-title {
      color: $dark-blue;
    }
  }
  .radio-list {
    padding-left: 34px;
    label {
      text-indent: -33px;
    }
  }
  h5 {
    font-size: 14px;
    color: $dark-blue;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  .note {
    font-size: 13px;
    color: $black;
    font-weight: 300;
    background-color: #f6f6f6;
    border-radius: 6px;
    padding: 10px;
    line-height: 1.8;
  }
}

.hover-underline:hover {
  color: $primary;
  text-decoration: underline !important;
}
.applied-job-fliter-box {
  display: none !important;
}
.applied-job-fliter {
  .applied-job-fliter-box {
    display: flex !important;
  }
}
.link {
  &:hover {
    text-decoration: underline !important;
  }
}
.receipt-date,
.response-date {
  width: 15%;
  padding-left: 5px;
}
.name-title {
  width: 19%;
  padding-left: 5px;
}
.email-id {
  width: 16%;
  padding-left: 5px;
}
.resume-clm {
  width: 23%;
  padding-left: 5px;
  white-space: normal;
  word-wrap: break-word;
}

.upload-icon {
  svg {
    font-size: 22px;
  }
}

// .selectd[class*="-container"] {
//   background-color: $grey;
// }

.selectd [class*="-control"] {
  background-color: $grey;
  border: 0;
  min-height: 45px;
  border-radius: 10px;
}

.selectd [class*="-menu"] {
  background-color: $white;
  z-index: 999;
}

.selectd [class*="-placeholder"] {
  color: $dark-blue;
  font-size: 13px;
  font-weight: 500;
}

.selectd [class*="-ValueContainer"] {
  padding-left: 20px;
}

.selectd [class*="-multiValue"] {
  div {
    color: $dark-blue;
    font-weight: 500;
  }
}
.css-1okebmr-indicatorSeparator,
.jobPostingType {
  display: none;
}
.applied-job-fliter .jobPostingType {
  display: flex;
}
.seekers-job-details.users-wrap .users-box {
  flex-basis: 45% !important;
}

.alert {
  &.confirm-alert {
    position: fixed;
    top: 10px;
    z-index: 9999;
    width: 78%;
    margin-left: 262px;
    left: 0;
    right: 0;
  }
}

.respons-heading {
  font-size: 13px;
  color: $dark-blue;
  font-weight: 600;
}

.respons-text {
  background-color: $grey;
  padding: 10px;
  margin-top: 20px;
  line-height: 1.8;
  border-radius: 8px;
  font-size: 13px;
  color: $black;
  font-weight: 400;
}

.hide-draft {
  .draftdropdown {
    display: none;
  }
}

.seeker-action-btns {
  .btn {
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 6px !important;
    min-width: 80px;
    font-weight: 500;
  }
}

.confirm-modal {
  .message-container {
    .message-header-box {
      background: $primary;
    }
    .message-body {
      padding: 50px;
      text-align: center;
    }
    .message-footer {
      justify-content: center;
      .btn {
        min-width: 78px;
        justify-content: center;
      }
    }
  }
}

.sub-form-title {
  border-bottom: 1px solid #fbf5f5;
  font-size: 16px;
  margin-bottom: 40px;
  padding-bottom: 7px;
  color: $dark-blue;
}

.square-radius {
  .btn {
    border-radius: 4px;
  }
}

.password-note {
  font-size: 13px;
  color: $dark-blue;
  font-weight: 500;
}

.error-message {
  font-weight: 500;
  color: #ff5145;
  font-size: 14px;
}

.hide-on-list {
  display: none;
}

.hide-from-dashboard {
  .seeker-action-btns {
    display: none;
  }
  .hide-on-list {
    display: block;
  }
}

.break-word {
  word-break: break-all;
}

.btn-account {
  .btn {
    min-width: 224px;
    justify-content: center;
  }
}

.text-decoration-underline {
  a {
    text-decoration: underline !important;
  }
}

.support-team-dashboard-list {
  .job-list li {
    flex-wrap: wrap;
    align-items: flex-start !important;
    .name {
      width: 65%;
      .job-post-date {
        text-align: left;
      }
    }
    .list-coulmn-right {
      flex-basis: 82%;
      display: flex;
      flex-wrap: wrap;
    }
    .job-post-date {
      flex-basis: 30%;
      padding-right: 0 !important;
      text-align: right;
    }
    .position,
    .company,
    .email,
    .subcat,
    .phone,
    .status,
    .statust {
      width: auto;
      background: #e5e5e5;
      margin: 5px 5px 5px 0;
      padding: 2px 7px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 12px;
      word-break: break-all;
    }
    .position {
      margin-left: 0px;
    }
  }
}

.job-seeker-dashboard-list {
  .job-list li {
    flex-wrap: wrap;
    align-items: flex-start !important;
    .name2 {
      width: 65%;
      .job-post-date {
        text-align: left;
      }
    }
    .list-coulmn-right {
      flex-basis: 82%;
      display: flex;
      flex-wrap: wrap;
    }
    .job-post-date {
      flex-basis: 30%;
      padding-right: 0 !important;
      text-align: right;
    }
    .email2 {
      display: none;
    }
    .phone2 {
      margin: 5px 0;
      font-weight: 500;
      font-size: 12px;
      margin-right: 0px;
      width: 100%;
    }
    .status2 {
      width: auto;
      background: #e5e5e5;
      margin: 5px 5px 5px 0;
      padding: 2px 7px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 12px;
    }
    .position {
      margin-left: 0px;
    }
  }
}

.text-blue {
  color: $dark-blue;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 13px;
}

.mark-and-grade {
  margin-bottom: 30px;
}

.custom-icon-size {
  font-size: 16px;
  //width: 36px;
  margin-right: 18px;
}

.flex1 {
  flex: 1;
}

.alphabate {
  span {
    width: 22px;
    display: inline-block;
    text-align: center;
    line-height: 1.8;
    font-size: 12px;
  }
  &.active {
    span {
      background-color: #012e51;
      border-radius: 4px;
      color: #fff;
    }
  }
}

.radi-icon {
  font-size: 10px;
  color: #383838;
  display: inline-flex;
  margin-top: 5px;
}

.radio-note {
  font-size: 13px;
}

.notification-dropdown {
  .dropdown-menu {
    left: auto;
    right: 0;
    //max-height: 364px;
    width: 363px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: #808d94 -1px 17px 12px 0px;
    border: 0;
    top: 65px;
    padding: 0px 0;
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
  }

  .dropdown-toggle {
    background-color: #2660a5;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 10px;
    border: 0;
    display: inline-flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    &::after {
      margin: 0;
    }
  }

  .user-thumb {
    background-color: #fff;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    border: 0;
    display: inline-block;
    overflow: hidden;
    margin-right: 15px;
  }

  .notification-text {
    font-size: 13px;
    color: #012e51;
    padding: 12px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    small {
      font-size: 100%;
      flex: 1 0 0;
    }
  }

  .view-notification-btn {
    padding: 0 20px 20px;
  }

  .profile-btns {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
    .btn {
      padding: 15px 20px;
      font-weight: 500;
      .icon {
        margin-right: 10px;
      }
      &.btn-primary {
        background-color: $dark-blue;
      }
    }
    .fill-signout {
      border-radius: 4px;
      padding: 12px 32px;
    }
  }
}

.num-count {
  position: absolute;
  user-select: none;
  cursor: default;
  font-size: 0.6rem;
  background: #ff2d17;
  width: 1.2rem;
  height: 1.2rem;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: -0.33rem;
  right: -0.66rem;
  box-sizing: border-box;
}

.admin-members {
  .g-width {
    width: 30% !important;
  }
  .count-width {
    width: 30% !important;
  }
}

.resume-tabs {
  .tab-content {
    border: 1px solid #2660a5;
    border-radius: 5px;
    overflow: hidden;
    .dashboard-box {
      border-radius: 0 !important;
    }
  }

  .nav {
    padding-left: 0px;
    .nav-link {
      margin-bottom: -6px;
      font-weight: 500;
      padding: 1rem 1rem;
      &.active,
      &:hover,
      &:focus {
        border-color: #2660a5 #2660a5 #2660a5;
        color: #012e51;
        font-weight: 500;
      }
    }
  }
}

.border-grey-1 {
  border-bottom: 2px solid #e8ebed;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker__month-read-view--down-arrow {
  top: 2px !important;
}

.react-datepicker__month-option--selected {
  left: 10px !important;
}

.react-datepicker__month-dropdown {
  width: 60% !important;
}

.react-datepicker__navigation--years {
  position: relative !important;
  text-indent: 0 !important;
}

.react-datepicker__navigation--years-upcoming::before {
  content: ">";
  position: absolute;
  color: #343434;
  bottom: 0px;
  margin: 0;
  font-size: 25px;
  z-index: 99999;
  left: 9px;
  transform: rotate(-90deg);
}

.react-datepicker__navigation--years-previous::after {
  content: ">";
  position: absolute;
  color: #343434;
  bottom: 10px;
  margin: 0;
  font-size: 25px;
  z-index: 99999;
  left: 9px;
  transform: rotate(90deg);
}

.btn-b {
  width: 20px;
  height: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}
.close-b {
  padding: 1px 4px;
  background-color: $danger;
  color: #fff;
  border-radius: 4px;
  i {
    font-size: 13px;
  }
}

.edit-b {
  //padding: 1px 4px;
  background-color: $primary;
  color: #fff;
  border-radius: 4px;
  i {
    font-size: 11px;
  }
}

.search-box {
  display: flex;
  width: 52%;
  .form-control {
    color: #012e51;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
  }
}

.manage-group-list {
  // .job-list li div {
  //   width: -webkit-fill-available !important;
  // }
  .m-width1 {
    width: 35% !important;
  }
  .m-width2 {
    width: 35% !important;
  }
  .m-width3 {
    width: 15% !important;
  }
  .m-width4 {
    width: 15% !important;
  }
}

.mobile-column-name {
  display: none;
}
.note-text {
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  margin-bottom: 10px;
}
.cancelled-text {
  display: inline-block;
  margin-right: 16px;
  font-weight: 600;
}
.w-55 {
  width: 58% !important;
}

.manage-admin-user-list {
  li {
    div {
      word-break: break-word;
    }
    .name2 {
      width: 15%;
    }
    .email2 {
      width: 30%;
      word-break: break-all;
    }
    .user-type {
      width: 15%;
      padding-right: 10px;
    }
    .group2 {
      width: 15%;
      padding-right: 10px;
    }
    .status2 {
      width: 10%;
      padding-right: 10px;
    }
    .action2 {
      width: 15%;
    }
  }
}

.pre-textarea {
  font-size: 12px !important;
  border: 0 !important;
  resize: inherit;
  padding: 0;
  max-height: 150px;
  height: 140px;
  width: 100%;
  &:focus {
    border: 0;
    outline: none;
  }
}

.pre-textarea-resume {
  font-size: 13px !important;
  border: 0 !important;
  resize: inherit;
  padding: 0;
  font-weight: 400;
  color: #012e51;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  display: inline-table;
  font-family: "Poppins", sans-serif;
  height: auto;
  width: 100%;
  &:focus {
    border: 0;
    outline: none;
  }
}

.seekers-job-details-page-new {
  .seeker-job-name {
    flex-basis: 40%;
  }
  .users-box.right {
    flex-basis: 60%;
  }
}

.terms-page {
  //height: 100vh !important;
 
}
.h-100v {
  height: 100vh !important;
}

 iframe > * {
    font-family: "Poppins", sans-serif;
  color: #000;
  }

//responsive

.menu-btn {
  padding: 12px 11px;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) and (max-width: 1720px){
.support-team-members-list {
  .support-member-list-wrapper {
    overflow-x: auto;
    .support-team-list {
      width: 100vw;
    }
  }
}
}

@media all and (device-width: 1180px) and (orientation:landscape) {
  .menu-btn {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 97%;
    max-width: 100%;
  }
  .sidebar {
    left: -250px;
    top: 67px;
    &.is-open {
      left: 0;
    }
  }
  .main {
    margin-left: 0;
    margin-top: 65px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
  }

  .message-header {
    margin-bottom: 15px;
    .page-title {
      font-size: 18px;
    }
  }
  .navbar {
    margin-left: 0;
    height: 67px;
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
  .header-signout-btn {
    display: none;
  }
  .notification-dropdown {
    .dropdown {
      position: static;
    }
    .dropdown-menu {
      left: auto;
      right: 13px;
      width: 94%;
      min-height: 200px;
      height: auto;
      top: 67px;
      max-height: 400px;
      overflow-y: auto;
    }
  }

  .menu-btn {
    padding: 12px 11px;
    margin-right: 10px;
  }
  .profile-dropdown {
    margin-left: 1rem !important;
  }

  .nav-right .profile {
    width: 50px;
    height: 50px;
  }

  .page-title {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .page-sub-title {
    font-size: 16px;
    color: #2660a5;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .dashboard-wrapper {
    .dashboard-box {
      padding: 15px 15px 15px;
      margin: 0px 0 15px !important;
      .btn-warning {
        margin-top: 0 !important;
      }
    }
  }
  .notification-dropdown .dropdown-toggle {
    position: relative;
    overflow: visible;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 820px) and (orientation:portrait) {
  .inbox-wrap .inbox-messages {
    max-height: 100vh
  }
}


@media screen and (max-width: 820px) {

.upload-resume-btns-mobile {
  .page-sub-title {
    flex-wrap: wrap;
    .badge {
      margin-bottom: 1rem !important;
    }
    .text-right.col {
      flex-basis: 100%;
    }
  } 
}

}

@media screen and (max-width: 933px) {
 
  .modal-title {
    font-size: 1.2rem;
  }
  .alert {
    &.confirm-alert {
      position: fixed;
      top: 10px;
      z-index: 9999;
      width: 95%;
      margin-left: 0;
      left: 10px;
      right: 10px;
    }
  }

  .dashboard-wrapper .dashboard-col-bg {
    float: left;
    width: 100%;
  }
  .support-team-list {
    .list-coulmn-right {
      display: block;
    }
    .headings {
      display: none;
    }
    li {
      flex-wrap: wrap;
      position: relative;
      .avatar {
        width: 50px !important;
        height: 50px !important;
        position: absolute;
        top: 15px;
      }
      div {
        width: 100% !important;
        padding: 5px 0;
        padding-left: 60px;
        &.w-50,
        &.width-t1,
        &.width-t2,
        &.width-t3,
        &.width-t4,
        &.width-t5 {
          padding-left: 0px !important;
        }
      }
    }
  }

  .sponsership-page .job-list {
    margin-top: 10px;
  }

  .job-seekers-list {
    li {
      div {
        width: 100% !important;
      }
    }
  }

  .job-seeker-dashboard-list .job-list li {
    display: flex;
    flex-direction: row;
    .job-post-date {
      flex-basis: 100%;
      text-align: left;
      padding-bottom: 0;
    }
    .list-coulmn-right {
      flex-basis: 74%;
      display: flex;
      padding-top: 0;
    }
    .name2 {
      padding-top: 0;
    }
    .mobile-column-name {
      display: none;
    }
    .avatar {
      width: 50px !important;
      height: 50px !important;
    }
    div {
      //width:76% !important;
    }

    .phone2 {
      width: 100% !important;
      margin-left: 0px;
      margin-top: 0;
    }
    .status2 {
      margin: 0 !important;
      margin-right: 10px !important;
      margin-left: 0px !important;
      display: inline-block;
      width: auto !important;
    }
  }

  // .sponsership-list {

  //   .headings {
  //     display: none;
  //   }
  //   li {
  //     flex-direction: column;
  //     .avatar {
  //       position: relative;
  //     }
  //     div {
  //       padding-left: 0;
  //       width: 100% !important;
  //       padding: 5px 0;
  //     }
  //   }
  // }

  .sponsership-list {
    .headings {
      display: none;
    }
    li {
      flex-wrap: wrap;
      position: relative;
      padding: 20px 15px !important;
      flex-direction: column;
      align-items: flex-start !important;
      .avatar {
        width: 50px !important;
        height: 50px !important;
        position: relative;
        top: -8px;
      }
      div {
        width: 100% !important;
        padding: 5px 0;
        padding-left: 0px;
        &.w-50,
        &.width-t1,
        &.width-t2,
        &.width-t3,
        &.width-t4,
        &.width-t5 {
          padding-left: 0px !important;
        }
      }
    }
  }

  .support-team-dashboard-list .job-list li {
    display: flex;
    flex-direction: row;
    .list-coulmn-right {
      flex-basis: 74%;
      display: flex;
      padding-top: 0;
    }
    .mobile-column-name {
      display: none;
    }
    .avatar {
      width: 50px !important;
      height: 50px !important;
      top: 0;
    }
    .job-post-date {
      flex-basis: 100%;
      text-align: left;
      padding-bottom: 0;
    }
    .position,
    .subcat,
    .statust {
      //background-color: transparent !important;
      margin-left: 0 !important;
      margin-right: 10px !important;
      display: inline-block;
      width: auto !important;
    }
  }
  .sponsership-dashboard-list .job-list li {
    display: flex;
    flex-direction: row;
    .name {
      width: 70% !important;
    }
    .list-coulmn-right {
      flex-basis: 74%;
      display: flex;
      padding-top: 0;
    }
    .mobile-column-name {
      display: none;
    }
  }
  .admin-dashboard-jobseeker {
    .job-list li {
      display: flex;
      align-items: center;
      width: 100%;
      .name {
        flex-basis: 70%;
      }
      .job-post-date {
        flex-basis: 30%;
        text-align: right;
        padding: 5px !important;
      }
      div {
        padding-left: 0;
      }
    }
  }
  .post-job-container {
    .saved-job {
      display: flex;
      align-items: center;
    }
  }

  .radio-buttons {
    flex-wrap: wrap;
  }

  .ques-info-icon img {
    margin-left: -20px !important;
    z-index: 1;
  }

  .post-job-footer-btns {
    .btn {
      margin-left: 0 !important;
      margin-right: 5px !important;
    }
  }

  .job-description {
    padding: 0;
  }

  .applied-list {
    padding: 10px 0 !important;
    li {
      padding: 15px 0px;
      border-radius: 0 !important;
    }
  }

  .mobile-list {
    .headings {
      display: none;
    }
    li {
      flex-wrap: wrap;
      position: relative;
      padding: 20px 15px !important;
      flex-direction: column;
      align-items: flex-start !important;
      .avatar {
        width: 50px !important;
        height: 50px !important;
        position: relative;
        top: -8px;
      }
      div {
        width: 100% !important;
        padding: 5px 0;
        padding-left: 0px;
        word-break: break-word;
      }
    }
  }

  .mobile-list {
    .mobile-coulmn {
      display: flex;
      .mobile-column-name {
        display: inline-block;
        font-size: 13px;
        margin-right: 5px;
        color: #215e8e;
        &::after {
          content: ":";
          color: #215e8e;
          position: relative;
        }
      }
      .mobile-column-content {
        flex: 1;
        text-align: left;
      }
      .hide-email-on-mobile {
        display: none;
      }
    }
   .action {
     text-align: left !important;
   }
  }
  .sponsership-list.mobile-list {
    .name.mobile-coulmn {
      width: 100% !important;
    }
  }
  .manage-group-list {
    .m-width1,
    .m-width2,
    .m-width3,
    .m-width4 {
      width: 100% !important;
    }
  }

  .support-team-list.mobile-list {
    .new-added-list {
      .mobile-column-name {
        display: inline-block !important;
    font-size: 13px;
    margin-right: 5px;
    color: #215e8e;
    word-break: break-all;
      }
      .hide-email-on-mobile {
        display: none !important;
      }
    }
  }
 
}
.info-i {
  cursor: pointer; float: right;
}

@media screen and (max-width: 580px) {
.home-page {
  header {
    border-bottom: 2px solid #fff;
  }
  .navbar-toggler {
    color: #fff;
    padding: 0;
    margin-right: 25px;
  }

  .sub-menu {
    min-width: 250px;
    max-width: 250px;
    margin: 0 !important;
    height: 100%;
    left: -250px !important;
    transition: all .5s;
    opacity: 1 !important;
    visibility: visible !important;
    top: 74px !important;
    padding-top: 10px !important;
    ul {
      width: 100%;
    display: block !important;
    margin: 0 auto;
    padding: 10px 10px 0;
      li {
        margin: 0 !important;
        margin-bottom: 15px !important;
        width: 100% !important;
        a {
          height: auto !important;
    width: auto !important;
    font-size: 11px;
    border: none;
    text-align: left;
    margin-left: 0;
    display: block;
    background-color: #fff;
    color: #012e51 !important;
    border-radius: 71px;
    line-height: 18px;
    text-align: center;
        }
      }
    }

    &.show-side-menu-mobile {
      left: 0px !important;
    }
  }
  }
  .signed-profile-dropdown {
    .overlay-dropdown {
      // background-color: #000;
      // position:absolute;
      // left: 0;
      // top:0;
      // width: 100%;
      // height: 100%;
      // display: block !important;
    }
  }
  .search {
    .btn {
      padding: 7px 18px !important;
    }
  }
 
  .custome-msg-container {
    .message-body,
    .message-header-box,
    .message-footer {
      padding: 15px !important;
    }
  }
  .users-wrap.job-details-wrap {
    padding: 30px 0px 0px;
    .job-title {
      margin-bottom: 5px;
    }
    .company-name {
      margin-bottom: 10px;
    }
    .left {
      flex-wrap: wrap;
    }
    .detail-view {
      .row .col {
        flex-basis: 100%;
      }
    }
    .users-box {
      .ml-auto {
        justify-content: space-between !important;
        width: 100%;
      }
      .btn {
        margin: 0 !important;
        padding: 8px 11px !important;
      }
    }
  }
  .resumes-page {
    .resume-list li {
      flex-basis: 100%;
    }
  }
  .w-25 {
    width: 100% !important;
  }
  .search-box {
    position: absolute;
    top: 66px;
    left: 0;
    background: #fff;
    margin: 0px 0 !important;
    width: 99%;
    flex-wrap: wrap;
    padding: 10px;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    > * {
      margin: 5px 0 !important;
    }
    select {
      margin: 10px 0;
    }
  }

  .applied-job-fliter .applied-job-fliter-box {
    margin-bottom: 10px;
  }
  .jobPostingType .form-group {
    width: 100%;
  }

  .upload-resume-btns-mobile {
    .bg-warning {
      margin-bottom: 0 !important;
    }
    h2 {
      display: block !important;
      float: left;
      width: 100%;
      .col {
        padding: 0 !important;
        padding-top: 0px !important;
      }
      .btn {
        margin-left: 0 !important;
        padding: 11px 10px;
        width: 100%;
        justify-content: center;
        //margin-top: 7px !important;
        &.upload-icon {
          margin-top: 10px !important;
        }
      }
    }
    .upload-cv-box {
      float: left;
      width: 100%;
    }
  }
  .jin-resumes {
    .col {
      width: 100% !important;
      flex-basis: 100% !important;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
    }
  }

  .network-list {
    .res-td {
      text-align: left !important;
    }
    div {
      padding-left: 0 !important;
    }
    .industry-category {
      text-align: left !important;
    }
    .receipt-date,
    .response-date,
    .email-id,
    .action {
      text-align: left !important;
    }
  }

  // .support-team-dashboard-list {
  //   .job-list {
  //     li div {
  //       padding-left: 0 !important;
  //       float: left;
  //       width: 50% !important;
  //     }
  //   }
  // }
  .admin-members {
    li {
      width: 100% !important;
    }
    .g-width {
      padding-left: 0 !important;
      float: left;
      width: 50% !important;
    }
    .count-width {
      width: 50% !important;
      text-align: right;
    }
  }

  .radio-new {
    padding-left: 35px;
    flex-basis: 100% !important;
    label {
      font-weight: 500;
      font-size: 14px;
      text-indent: -25px;
      display: inline-block;
    }
  }

  .radio-new input[type="radio"] + .radio-label:before {
    left: -12px;
    margin-right: 0;
  }

  header .nav-link {
    padding: 0.5rem 0.5rem;
  }
  header {
    padding-top: 0.8rem !important;
    padding-bottom: 0.5rem !important;
  }
  .brand img {
    height: 35px !important;
  }
  .login-doropdown .dropdown-menu {
    min-width: 390px !important;
  }
  .main-banner {
    padding-top: 30px !important;
  }
  .outline-form.public-wrapper {
    .col {
      flex-basis: 100% !important;
    }
    .custom-control.custom-checkbox {
      margin: 0 !important;
    }
    h5.sub-form-title.border-none {
      width: 100%;
      display: block !important;
    }
    h5.sub-form-title {
      margin-top: 15px !important;
      width: 50%;
      display: inline-block !important;
    }
    .register-user-btn {
      width: 50%;
      display: inline-block !important;
      margin: 0;
      .col-md-8 {
        text-align: right;
        padding: 0;
      }
    }
    .square-radius {
      margin-top: 15px !important;
    }
  }
  .view-resume-details-btns {
    display: flex;
    justify-content: space-between;
    .btn {
      padding: 9px 5px !important;
      font-size: 11px !important;
    }
  }
  .post-job-form {
    .col {
      flex-basis: 100% !important;
    }
    p {
      font-size: 12px;
    }
  }

  .inbox-wrap {
    display: block;
    .inbox-menu {
      padding-top: 30px;
      ul {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        li {
          a {
            padding: 7px 10px;
            border-radius: 30px;
            font-size: 11px;
          }
        }
      }
    }

    .inbox-messages {
      padding: 10px !important;
      max-height: 220px;
      .list-group {
        margin: 0 !important;
      }
      .list-group-item {
        margin: 7px 0 !important;
      }
    
    }
    .inbox-message {
      position: fixed;
      bottom: 0;
      z-index: 1;
      background: #fff;
      width: 100%;
      margin: 0;
      left: 0;
      padding: 20px 10px 0;
      z-index: 900;
      border-top: 1px solid #ccc;
      box-shadow: -6px 8px 16px 0px #686868;
      border-left: 0;
    }
  }

  .account-information-form {
    .col,
    .col-md-6 {
      p {
        margin-bottom: 0;
        position: relative;
        top: -13px;
      }
    }
  }
  .post-job-footer-btns {
    justify-content: space-between;
    width: 100%;
    display: flex;
    .btn {
      padding: 11px 14px;
    }
  }
  .resume-content h3 {
    font-size: 15px;
  }
  .resume-content h2 {
    font-size: 15px;
  }
  .page-sub-title-with-back {
    font-size: 16px;
  }
  .sponsership-user-detail {
    .user-details {
      flex-wrap: wrap;
      margin-top: 20px;

      .user-avatar {
        height: 60px;
        width: 60px;
      }
      .actions-buttons {
        margin-top: 10px;
        &.w-55 {
          width: 100% !important;
          display: flex;
    flex-wrap: wrap;
        }
        .btn {
          margin: 7px 0 !important;
          padding: 11px 11px;
          margin-right: 10px !important;
        }
        .cancelled-text {
          display: block;
          margin-bottom: 10px;
          width: 100%;
          text-align: left !important;
        }
      }
    }
    .users-wrap {
      display: block;
      .notification {
        margin-top: 40px;
      }
    }
  }
  .page-sub-title {
    font-size: 14px;
    margin-bottom: 31px;
    .badge {
      margin: 5px 20px 10px 10px !important;
    }
  }
  .seekers-job-details {
    flex-direction: column;
    .users-box {
      order: 2;
      & + .users-box {
        order: 1;
      }
      .job-acrions {
        .btn {
          margin-left: 10px !important;
        }
      }
    }
  }
  .upload-cv-box {
    .btn {
      padding: 6px 6px;
      font-size: 12px;
    }
  }

  .mobile-list {
    .mobile-coulmn {
      display: flex;
      .mobile-column-name {
        display: inline-block !important;
        font-size: 13px;
        margin-right: 5px;
        color: #215e8e;
        &::after {
          content: ":";
          color: #215e8e;
          position: relative;
        }
      }
      .mobile-column-content {
        flex: 1;
      }
    }

    .action .btn {
      padding: 5px 10px;
    }
  }
  .job-seeker-dashboard-list {
    .mobile-list .mobile-coulmn {
      .mobile-column-name {
        display: none !important;
      }
      &.seeker-action-btns {
        display: none !important;
      }
    }
  }

  .support-team-dashboard-list {
    .support-team-list.mobile-list li {
      div {
        width: auto !important;
      }
      .avatar {
        width: 50px !important;
      }
    }
    .mobile-list .mobile-coulmn {
      .mobile-column-name {
        display: none !important;
      }
      &.seeker-action-btns {
        display: none !important;
      }
    }
  }
  .recent-communication {
    margin-top: 0px !important;
    h3 {
      margin-bottom: 10px;
    }
    .message-box {
      width: 100%;
      .message-details {
        p {
          font-size: 12px;
        }
      }
    }
  }
  .applied-list {
    li {
      .name {
        width: 100% !important;
      }
      .email {
        width: 100% !important;
        margin-bottom: 10px;
      }
      .btn {
        margin: 0px 0 !important;
      }
    }
    
  }

  .profile-dropdown .dropdown-menu {
    width: 350px;
    top:57px
  }
  .users-wrap {
    .users-box {
          flex-basis: 100%;
      }
    }
    .network-page {
      .d-flex.mb-3 {
        flex-wrap: wrap !important;
        margin-bottom: 0 !important;
      }
      .form-group {
        width: 100% !important;
        margin-top: 10px !important;
      }
    }
    .info-select-dropdown  {
      .info-i {
        margin-right: 10px;
      }
      .inputclass.form-control {
        width: 96%;
      }
    }

    .account-information-form {
      .custom-checkbox {
        margin-bottom: 0px !important;
      }
      .btn-account {
        margin-top: 15px !important;
      }
    }
    .seekers-job-details-page-new {
      .users-box.left {
        display: block !important;
      }
      .users-box.right {
        margin-top: 20px !important;
        .btn {
          margin-left: 10px !important;
        }
      }
    }

    .sponsership-page.network-page .job-list, .sponsership-page .network-list {
      width: 86%;
    }

    .network-page .alphabates {
      margin-left: 10px;
    }
}


@media screen and (max-width: 375px) {
  header {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  .brand img {
    height: 38px !important;
  }
 
  .btn {
    padding: 7px 15px;
  }

  .confirm-modal {
    .modal-dialog {
      .modal-content {
        width: 90%;
      }
    }
  }
  .pagination {
    align-items: center;
    flex-wrap: wrap;
  }
  .resumes-page {
    .resume-block-mobile {
      display: block !important;
      .page-sub-title {
        margin-bottom: 12px;
      }
      .saved-resume {
        text-align: left !important;
      }
      .icon-d {
        width: 35px;
    height: 35px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .modal-dialog {
    width: 87%;
  }
  .confirm-modal {
    padding: 0;
    .modal-dialog {
      .modal-content {
        width: 99%;
      }
    }
  }
}

// Demo touchpoint indicator
.hotspot-container {
  position: absolute;
  display: inline-block;
  z-index: 6900;

  overflow: visible; /* Ensure the hotspot bubble is visible outside the container */

  /* Animate position changes */
  transition: left 1s ease-in-out, top 1s ease-in-out;

  top: 1px;
  left: 1px;
}

.hotspot {
  width: 21px;
  height: 21px;
  background-color: rgba(25, 92, 174, 0.90);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: relative;

  /* Add pulsating glow effect */
  background-color: rgba(25, 92, 174, 0.90);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.50);
  animation: glowEffect 1.5s ease-in-out infinite;
}

.hotspot-bubble {
  position: absolute;
  background-color: rgba(25, 92, 174, 0.98);
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.3);
  font-family: Arial, sans-serif;
  font-size: 14px;

  min-width: 300px; 
  white-space: normal;
  word-wrap: break-word;
}

.hotspot-container.hidden, .hotspot.hidden, .hotspot-bubble.hidden {
  display: none;
}

/* Bubble positioning */
.bubble-top {
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}

.bubble-top-right {
  bottom: 35px;
  left: -15px;
}

.bubble-top-left {
  bottom: 35px;
  right: -50%;
}

.bubble-bottom {
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
}

.bubble-left {
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}

.bubble-right {
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
}

.bubble-bottom-left {
  top: 35px;
  right: -50%;
}

.bubble-bottom-right {
  top: 35px;
  left: -15px;
}

/* Arrow pointers for each bubble position */
.bubble-top:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: rgba(25, 92, 174, 0.90) transparent transparent transparent;
}

.bubble-top-right:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 15px;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(25, 92, 174, 0.90) transparent transparent transparent;
}

.bubble-top-left:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 90%;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(25, 92, 174, 0.90) transparent transparent transparent;
}

.bubble-bottom:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgba(25, 92, 174, 0.90) transparent;
}

/* Arrow pointers for each new bubble position */
.bubble-bottom-left:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 90%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgba(25, 92, 174, 0.90) transparent;
}

.bubble-bottom-right:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 15px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgba(25, 92, 174, 0.90) transparent;
}

.bubble-left:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(25, 92, 174, 0.90);
}

.bubble-right:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent rgba(25, 92, 174, 0.90) transparent transparent;
}

/* Pulsating animation */
@keyframes glowEffect {
  0% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}
